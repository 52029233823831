import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { BuyTicketComponent } from './views/buy/buy.component';
import { PaymentComponent } from './views/paymentStatus/payment.component';
import { AuthGuardService as AuthGuard } from './auth.service';
import { RoleGuardService as RoleGuard } from './role-guard.service';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'buy',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'buy',
    component: BuyTicketComponent,
    data: {
      title: 'Online Ticket'
    }
  },
  {
    path: 'payment',
    component: PaymentComponent,
    data: {
      title: 'Payment Status'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'transactions',
        // canActivate: [RoleGuard],
        // data: { expectedRole: 1 },
        loadChildren: () => import('./views/sales/sales.module').then(m => m.SalesModule)
      },
      {
        path: 'users',
        canActivate: [RoleGuard],
        data: { expectedRole: 1 },
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'tickets',
        loadChildren: () => import('./views/tickets/tickets.module').then(m => m.TicketsModule)
      },
      {
        path: 'reports',
        // canActivate: [RoleGuard],
        // data: { expectedRole: 3 },
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'operator',
        // canActivate: [RoleGuard],
        // data: { expectedRole: 3 },
        loadChildren: () => import('./views/operator/operator.module').then(m => m.OperatorModule)
      },
      {
        path: 'profile',
        // canActivate: [RoleGuard],
        // data: { expectedRole: 3 },
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'dayblock',
        // canActivate: [RoleGuard],
        // data: { expectedRole: 3 },
        loadChildren: () => import('./views/dayblock/dayblock.module').then(m => m.DayBlockModule)
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
