import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/sjpay-config';


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  apiUrl = Config.apiConfig.url;

  constructor(private http: HttpClient) { }

  public isAuthenticated(): Boolean {
    let userData = localStorage.getItem('userInfo')
    if (userData && JSON.parse(userData)) {
      return true;
    }
    return false;
  }

  public setUserInfo(user) {
    localStorage.setItem('userInfo', JSON.stringify(user));
  }

  public resetUserInfo(){
    localStorage.removeItem('userInfo');
  }

  public resetNavItems(){
    localStorage.removeItem('navItems');
  }

  public validate(username, password) {
      let data = {
        username: username,
        password: password
      }
      return this.http.put(`${this.apiUrl}/user/login`, data).toPromise()
  }
}