import { Component, OnInit } from '@angular/core';
import { Data } from '../../data/ticket';
import { Config } from '../../../config/sjpay-config';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { getFullYear } from 'ngx-bootstrap/chronos';
import { setFullYear } from 'ngx-bootstrap/chronos/utils/date-setters';
import { getLocaleDateTimeFormat } from '@angular/common';
// var randomstring = require("randomstring");
@Component({
  selector: 'app-dashboard',
  templateUrl: 'buy.component.html',
  styleUrls: ['./buy.component.css']
})
export class BuyTicketComponent implements OnInit {

  config = {
    class: 'modal-dialog-centered'
  }
  modalRef!: BsModalRef;

  date: any = new Date()
  passengers: any = [
    { name: '', ic: '' }
  ]
  phoneNo: any;
  email: any;
  count: any = 1;
  departureType: any;
  country: any;

  bsInlineValue = new Date();
  bsInlineRangeValue: Date[];
  maxDate = new Date();
  minDate = new Date();
  countryValue: any;
  value = '';
  blockedDays = [];
  disabledDates = [
    new Date('2023-04-04'),
    new Date('2023-04-05')
  ];

  createCategorySubscribe: any;
  createBillSubscribe: any;
  createTicketSubscribe: any;


  constructor(private svcData: Data, private modalService: BsModalService) { }

  async ngOnInit() {
    let blockedDate = await this.svcData.getBlockedDays();
    this.blockedDays = blockedDate.map(x => {
      let y;
      y = new Date(x);
      return y
    });
  }

  dateChange(event) {
    this.date = event
    // console.log(this.date);
  }

  defaultCountry(countryValue) {
    // this.countryValue = value

    console.log("default country: " + this.countryValue)
    this.country = this.countryValue


    for (const passenger of this.passengers) {
      passenger.pass_country = this.country
    }

    return this.countryValue
  }

  dateFilter = (d: Date) => {
    return [1, 5, 10, 21].indexOf(+d.getDate()) == -1;
  };

  generatePassengersForm(number) {

    this.count = number
    this.passengers = []
    for (let i = 1; i <= number; i++) {
      this.passengers.push({ name: '', ic: '', isChild: false })
      // if()
    }
    console.log(this.passengers)
  }

  async submit(modal: any) {
    // console.log(modal)
    // console.log(this.count)
    modal.show();
    let error;

    if (!this.departureType) error = 'Select departure';
    if (!this.phoneNo) error = 'Enter phone no';
    if (!this.email) error = 'Enter email';
    if (!this.date) error = 'Enter date';

      if (!error) {

        let kids: number = 0;

        for (let i = 0; i < this.count; i++) {
          if (this.passengers[i].isChild == true) {
            kids = kids + 1;
          }
        }

        let nombor: number;
        nombor = this.count - kids;
        // console.log("price for ticket is "+nombor+" x RM 5")

        let detail = {
          name: this.passengers[0].name,
          amount: (nombor * 5) + 1,
          email: this.email,
          phone: this.phoneNo,
          count: this.count,
        }

        let billCode = await this.svcData.createToyyibPayBill(detail);
        let paymentUrl = Config.toyyibPayConfig.paymentUrl + billCode
        let code = (Math.random() + 1).toString(36).substring(3);

        console.log(this.date)
        let data = {
          ticketCode: code,
          count: this.count,
          ticketDate: this.date,
          amount: nombor * 5,
          email: this.email,
          phoneNo: this.phoneNo,
          boat: parseInt(this.departureType),
          billCode: billCode,
          ticketFlag: 1,
          country: this.country,
          passengers: this.passengers
        }

        // if(this.passengers.isPaid==1){

        let createTicket = await this.svcData.createTicket(data);
        // TODO : ADD creation checking
        // }

        // else{
        //   console.log("belum payment")
        // }   

        setTimeout(() => {
          modal.hide();
          window.location.href = paymentUrl;
          modal.hide()
        }, 3000);
      }
  }

}
