import { Component, OnInit } from '@angular/core';
import { Data } from '../../data/ticket';
import { Config } from '../../../config/sjpay-config';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  config = {
    class: 'modal-dialog-centered'
  }
  modalRef!: BsModalRef;
  refNo: any;
  status: any;
  reason: any;
  billCode: any;
  transactionId: any;
  amount: any;
  transactime: any;
  constructor(private svcData: Data, private activeRoute: ActivatedRoute, private router: Router,private modalService: BsModalService) {
  }


  ngOnInit(): void {

    this.activeRoute.queryParams.subscribe((params: Params) => {

      this.status = params['status_id'];
      this.billCode = params['billcode'];
      this.transactionId = params['transaction_id'];

      console.log(this.status, this.billCode, this.transactionId)

    });

    this.print();
  }

  print() {
    console.log(this.status, this.billCode, this.transactionId)
    if (this.status == 1) {
      this.svcData.updateTicket(this.billCode);
    }

  }


  gotoHome(modal:any) {
    modal.show()

    setTimeout(() => {
      modal.hide();
      this.router.navigate(['/buy']);
      modal.hide()
    }, 3000);
    
  }
}
