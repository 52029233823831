// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { LoginComponent } from './login.component';
import { FormsModule } from '@angular/forms';
// RECOMMENDED
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
// Theme Routing
// import { BuyRoutingModule } from './buy-routing.module';

@NgModule({
  imports: [
    CommonModule,
    // BuyRoutingModule,
    ChartsModule,
    FormsModule,
    ModalModule,
    BsDatepickerModule.forRoot(),
  ],
  declarations: [
    LoginComponent
  ]
})
export class LoginModule { } 