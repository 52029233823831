export const firebaseConfig = {
  apiKey: 'AIzaSyAj4WW9ZA7Pk9HpyTUpOSLMVx0jPZtrxh4',
  authDomain: 'ezz-dev.firebaseapp.com',
  projectId: 'ezz-dev',
  storageBucket: 'ezz-dev.appspot.com',
  messagingSenderId: '1099318985686',
  appId: '1:1099318985686:web:a959d81369d76c4f05da77',
  measurementId: 'G-X0BT8X1HSD'
};

export const apiConfig = {
  url: 'https://sjpay.application-solution.com:8084/api',
  paymentCallback: 'https://sjpay.application-solution.com/#/payment'
};

export const emailConfig = {
  email: 'admin@bigcircle55.com',
  password: 'nr51n#B18Y'
};

export const toyyibPayConfig = {
  createCategoryURL: 'https://dev.toyyibpay.com/index.php/api/createCategory',
  createBillUrl: 'https://dev.toyyibpay.com/index.php/api/createBill',
  secretKey: '66hcymvg-4okl-s8av-fpnl-x3ag6ff6tg4v',
  paymentUrl: 'https://dev.toyyibpay.com/'
};
