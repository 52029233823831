import { Component, OnDestroy, OnInit } from '@angular/core';
import { navItems } from '../../_nav';
import { AuthService } from '../../coreauth.service';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-settings',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {

  public subscription: any;
  public sidebarMinimized = false;
  role: any;
  public navItems = [];

  constructor(private auth: AuthService, private router: Router, public translate: TranslateService) {
    this.role = JSON.parse(localStorage.getItem('userInfo'))
    console.log("masuk")
    const translatedNavs = navItems.map(items => { this.translater(items); return items; });
      this.navItems = [];
      translatedNavs.forEach(val => this.navItems.push(Object.assign({}, val)));

      if (this.role.user.access === 1){
        this.navItems = [this.navItems[0],this.navItems[1],this.navItems[2],this.navItems[3],this.navItems[4],this.navItems[5],this.navItems[7]]
      }

      else if (this.role.user.access === 2 || this.role.user.access === 3 || this.role.user.access === 4) {
        this.navItems = [this.navItems[0],this.navItems[1],this.navItems[3],this.navItems[4],this.navItems[5],this.navItems[6]]
      }

      else if (this.role.user.access === 5) {
        this.navItems = [this.navItems[0],this.navItems[1],this.navItems[5],this.navItems[6]]
      }
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|may/) ? browserLang : 'en');
    translate.use('en');

  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  async logout() {
    this.auth.resetUserInfo();
    this.router.navigate(['/login'])
  }

  setLanguage(language) {
    this.translate.use(language);
    this.translate.setDefaultLang(language);
    // window.location.reload();
  }

  translater(item): void {
    const trans = this.translate.instant(`${item.name}`);
    item.name = trans;
    // if (item.children && item.children.length > 0) {
    //   item.children.map((child: any) => this.translater(child));
    // }
  }

  ngOnInit(): void {
    // let role = JSON.parse(localStorage.getItem('userInfo'))
  //   this.subscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
  //     console.log("masukkk")
      
  //   });
  }
}
