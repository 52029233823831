import { Component } from '@angular/core';
import { Data } from '../../data/ticket';
// import { Config } from '../../../config/sjpay-config';
import { AuthService } from '../../coreauth.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  username: any;
  password: any;
  error: any;
  isActive: any;
  showError: any = false;

  constructor(private svcData: Data, private auth: AuthService, private router: Router) {

  }


  async login(modal: any) {
    modal.show()
    this.showError = false
    this.auth.validate(this.username, this.password)
      .then((response: any) => {
        console.log(response)
        console.log("status: "+response.isActive)
        if (response && response.access !== 2 && response.isActive==true) {
          setTimeout(() => {
            modal.hide();
            this.auth.setUserInfo({ 'user': response });
            this.router.navigate(['/dashboard'])
          }, 3000);

        } else if (response.isActive==false || response.access==2) {
          setTimeout(() => {
            modal.hide();
            this.error = 'Please contact your system administrator'
            this.showError = true
          }, 3000);
        }
          else {
          setTimeout(() => {
            modal.hide();
            this.error = 'Wrong username or password'
            this.showError = true
          }, 3000);
        }
      })

      




  }

  ngOnInit() {
    this.auth.resetUserInfo()
  }

}
