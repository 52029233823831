import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    
  },
  {
    name: 'Sales',
    url: '/transactions/sales',
    icon: 'cil-dollar'
  },
  {
    name: 'Users',
    url: '/users/list',
    icon: 'cil-people'
  },
  {
    name: 'Boat Operator',
    url: '/operator/list',
    icon: 'cil-boat-alt'
  },
  {
    name: 'Ticket Entry',
    url: '/tickets/entry',
    icon: 'cil-notes'
  },
  {
    name: 'Reporting',
    url: '/reports/list',
    icon: 'cil-graph'
  },
  {
    name: 'Profile',
    url: '/profile',
    icon: 'cil-people'
  },
  {
    name: 'Blocked Days',
    url: '/dayblock/list',
    icon: 'cil-calendar'
  },
  // {
  //   name: 'Reports',
  //   url: '/theme/typography',
  //   icon: 'cil-bar-chart'
  // },
 
];

