import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './coreauth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot) {

    const expectedRole = route.data.expectedRole;
    const role = JSON.parse(localStorage.getItem('userInfo'))

    if (!this.authService.isAuthenticated() || role.user.access !== expectedRole) {
      this.router.navigate(['dashboard']);
      return false;
    }
    return true;
  }
}	