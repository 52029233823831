import { Injectable } from '@angular/core';

// import { AuthService } from '../coreauth.service';
// import { auth } from 'firebase/app';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Router, ActivatedRoute, Params } from '@angular/router';

import { HttpClient, HttpParams } from '@angular/common/http'
import { HttpHeaders } from '@angular/common/http';

import { take, map, tap } from 'rxjs/operators';
import { Config } from '../../config/sjpay-config';
import { BoundAttribute } from '@angular/compiler/src/render3/r3_ast';
// import { type } from 'os';

let httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
};


@Injectable()


export class Data {

    uid: any;
    user_type: any;
    username: any;
    currentUser: any;

    apiUrl = Config.apiConfig.url;
    callbackUrl = Config.apiConfig.paymentCallback
    toyyibConfig = Config.toyyibPayConfig

    constructor(public http: HttpClient, public router: Router) {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.currentUser = userInfo?.user;
        // console.log("currentUser: ")
        // console.log(this.currentUser)
    }

    async getBlockedDate() {
        return this.http.get(`${this.apiUrl}/ticket/getBlockedDates`)
            .toPromise().then((data: any) => {
                return data
            })
    }

    async getBlockedDays() {
        return this.http.get(`${this.apiUrl}/ticket/getBlockedDays`)
            .toPromise().then((data: any) => {
                return data
            })
    }

    async setBlockDate(date) {
        return this.http.put(`${this.apiUrl}/ticket/blockDate`, date)
            .toPromise().then((data: any) => {
                // console.log(data);
                return data
            })
    }

    async updateBlockDate(date) {
        return this.http.put(`${this.apiUrl}/ticket/updateBlockDate`, date)
            .toPromise().then((data: any) => {
                // console.log(data);
                return data
            })
    }

    async deleteBlockDate(id) {
        return this.http.delete(`${this.apiUrl}/ticket/deleteBlockedDate/${id}`)
            .toPromise().then((data: any) => {
                // console.log(data);
                return data
            })
    }

    getDashboarSummary(): Observable<any> {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.currentUser = userInfo.user;
        console.log("this.currentUser")
        console.log(this.currentUser)
        return this.http.get(`${this.apiUrl}/ticket/summary/${this.currentUser.jetty}/${this.currentUser.username}/${this.currentUser.access}`)
            .pipe(
                take(1),
                map((state: any) => { return state })
            )
    }

    getUserSummary(): Observable<any> {
        return this.http.get(`${this.apiUrl}/user/list`)
            .pipe(
                take(1),
                map((state: any) => { return state })
            )
    }

    getOperatorSummary(): Observable<any> {
        return this.http.get(`${this.apiUrl}/operator/list`)
            .pipe(
                take(1),
                map((state: any) => { return state })
            )
    }

    async userLogin(username, password) {
        let data = {
            username: username,
            password: password
        }
        return this.http.put(`${this.apiUrl}/user/login`, data)
            .toPromise().then((user: any) => {
                // console.log(user.status)
                return user
            })
    }



    async createToyyibPayCategory() {
        let data = new HttpParams({
            fromObject: {
                catname: 'Ticket Booking',
                catdescription: 'Ticket Booking at Jeti Setiu',
                userSecretKey: this.toyyibConfig.secretKey
            }

        })

        return this.http.post(`${this.toyyibConfig.createCategoryURL}`, data, httpOptions)
            .toPromise().then((data: any) => {
                return data.CategoryCode
            })
    }

    async createToyyibPayBill(detail) {
        let categCode = await this.createToyyibPayCategory()
        let data = new HttpParams({
            fromObject: {
                'userSecretKey': this.toyyibConfig.secretKey,
                'categoryCode': categCode,
                'billName': 'Setiu Jetty Pay Web Booking',
                'billDescription': `Ticket Booking for x${detail.count} person`,
                'billPriceSetting': '1',
                'billPayorInfo': '1',
                'billAmount': (detail.amount * 100).toString(),
                // 'billReturnUrl': 'http://localhost:4200/#/payment',
                'billReturnUrl': this.callbackUrl,
                // 'billCallbackUrl': 'http://test/paystatus',
                // 'billExternalReferenceNo': 'Test123',
                'billTo': detail.name,
                'billEmail': detail.email,
                'billPhone': detail.phone,
                'billSplitPayment': '0',
                'billSplitPaymentArgs': '',
                'billPaymentChannel': '0',
                'billContentEmail': 'Thank you for your ticket booking!',
                'billChargeToCustomer': '1',
                'billExpiryDate': '17-12-2030 17:00:00',
                'billExpiryDays': '1'
            }

        })

        return this.http.post(`${this.toyyibConfig.createBillUrl}`, data)
            .toPromise().then((data: any) => {
                console.log(data)
                let BillCode = data[0].BillCode;

                return BillCode
            })
    }

    async createTicket(data) {
        // console.log(data)
        return this.http.post(`${this.apiUrl}/ticket`, data)
            .toPromise().then((data: any) => {
                // console.log(data);
                return data
            })
    }

    async createUser(data) {
        return this.http.post(`${this.apiUrl}/user`, data)
            .toPromise().then((data: any) => {
                // console.log(data);
                return data
            })
    }

    async createOperator(data) {
        // console.log("createOperator");
        // console.log(data);
        return this.http.post(`${this.apiUrl}/operator`, data)
            .toPromise().then((data: any) => {
                console.log(data);
                return data
            })
    }

    async updateUser(data) {
        return this.http.put(`${this.apiUrl}/user/update`, data)
            .toPromise().then((data: any) => {
                // console.log(data);
                return data
            })
    }

    async updateJetty(data) {
        return this.http.put(`${this.apiUrl}/user/update`, data)
            .toPromise().then((data: any) => {
                // console.log(data);
                return data
            })
    }

    async updateOperator(data) {
        console.log("masuk ticket.ts")
        return this.http.put(`${this.apiUrl}/operator/update`, data)
            .toPromise().then((data: any) => {
                // console.log(data);
                return data
            })
    }

    async updateTicket(billCode) {
        let data = {
            billCode: billCode
        }
        return this.http.put(`${this.apiUrl}/ticket/updatePayment`, data)
            .toPromise().then((data: any) => {
                // console.log(data);
                return data
            })
    }

    async archiveTicket(ticketCode) {
        let data = {
            id: ticketCode
        }
        return this.http.put(`${this.apiUrl}/ticket/archive`, data)
            .toPromise().then((data2: any) => {
                console.log(data2);
                return data2
            })

    }

    async ticketHistory(boat, ticketCode, username, type) {
        let data = {
            boat: boat,
            ticketCode: ticketCode,
            username: username,
            type: type,
        }
        return this.http.post(`${this.apiUrl}/ticket/createHistory`, data)
            .toPromise().then((data2: any) => {
                console.log(data2);
                return data2
            })

    }

    async getTicketHistory(ticketCode) {

        return this.http.get(`${this.apiUrl}/ticket/getHistory/${ticketCode}`)
            .toPromise().then((data2: any) => {
                console.log(data2);
                return data2
            })

    }



    getMember(): Observable<any> {
        return this.http.get(`${this.apiUrl}members`)
            .pipe(
                take(1),
                map((state: any) => { return state.data })
            )
    }

    getMerchant(): Observable<any> {
        return this.http.get(`${this.apiUrl}merchants`)
            .pipe(
                take(1),
                map((state: any) => { return state.data })
            )
    }

    getFloor(): Observable<any> {
        return this.http.get(`${this.apiUrl}floor`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state.data;

                    data = data.slice(2)
                    return data
                })
            )
    }

    getUserById(userId: any): Observable<any> {
        return this.http.get(`${this.apiUrl}/getUserById/${userId}`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getByTicketCode(ticketCode: any): Observable<any> {
        return this.http.get(`${this.apiUrl}/ticket/history/${ticketCode}`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getAllFiles(userId: any): Observable<any> {
        return this.http.get(`${this.apiUrl}/getAllFiles/${userId}`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getTrackingById(fileId: any): Observable<any> {
        return this.http.get(`${this.apiUrl}/getTrackingById/${fileId}`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getReferralsByUId(uid: any): Observable<any> {
        return this.http.get(`${this.apiUrl}/getReferral/${uid}`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getAllReferral(): Observable<any> {
        return this.http.get(`${this.apiUrl}/getAllReferral`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getAllSolicitors(uid: any): Observable<any> {
        // console.log(uid)
        return this.http.get(`${this.apiUrl}/getAllSolicitor/${uid}`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getAllAgent(): Observable<any> {
        return this.http.get(`${this.apiUrl}/getAllAgent`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getAllAgentByUserId(uId: any): Observable<any> {
        return this.http.get(`${this.apiUrl}/getAllAgentByUid/${uId}`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getAllPartner(): Observable<any> {
        return this.http.get(`${this.apiUrl}/getAllPartner`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getAllUsers(): Observable<any> {
        return this.http.get(`${this.apiUrl}/getAllUsers`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getReferralSummary(): Observable<any> {
        return this.http.get(`${this.apiUrl}/getReferralSummary`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getAllPartnerSummary(): Observable<any> {
        return this.http.get(`${this.apiUrl}/getAllPartnerSummary`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

    getLoanSummary(): Observable<any> {
        return this.http.get(`${this.apiUrl}/getAllLoanSummary`)
            .pipe(
                take(1),
                map((state: any) => {
                    let data = state;
                    return data
                })
            )
    }

}